import { FC, useEffect, useState } from "react";
import { Button, Grid, Typography, Paper, Box, Container } from "@mui/material";
import measures from "../../data/measures";
import measureTypes from "../../data/measureTypes";
// import config from "../../config/config";
import { makeStyles } from "@mui/styles";
import { useSearchParams } from "react-router-dom";
import IMeasure from "../../interface/measure";
import MeasuresPreview from "./preview/MeasuresPreview";
import PaginationControl from "../../components/PaginationControl";
import Breadcrumb from "../../components/Breadcrumb";
import useCurrentWidth from "../../hooks/useCurrentWidth";
import Footer from "../../components/Footer";
import IPageProps from "../../interface/pageProps";

const useStyles = makeStyles({
  gridContainer: {
    "@media (max-width: 767.99px)": {
      padding: "0.5rem",
    },
    "@media (min-width: 768px)": {
      padding: "1rem",
    },
  },
  gridItem: {
    display: "flex",
    flexDirection: "column",
    padding: "0 1rem 1rem 1rem",
    "@media (max-width: 767.99px)": {
      padding: "0 0.5rem 0.5rem 0.5rem",
    },
    "@media (min-width: 768px)": {
      padding: "0 1rem 1rem 1rem",
    },
  },
});

const MeasuresPreviewPage: FC<IPageProps> = ({ toggleLoading, loading }) => {
  const classes = useStyles();
  const limit = 5;
  const width = useCurrentWidth();

  const [searchParams, setSearchParams] = useSearchParams();

  const [filteredMeasures, setFilteredMeasures] = useState<IMeasure[]>([]);
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState(
    Math.ceil(Number((measures.length / limit)))
  );

  const handleTypeButton = (event: React.MouseEvent<HTMLButtonElement>) => {
    const target: HTMLButtonElement = event.currentTarget;
    const value = target.value;
    setSearchParams({ typ: value, subtyp: "1" });
  };

  const handleSubTypeButton = (event: React.MouseEvent<HTMLButtonElement>) => {
    const target: HTMLButtonElement = event.currentTarget;
    const value = target.value;
    const type = searchParams.get("typ");
    // console.log(typeof type);
    if (typeof type === "string") {
      setSearchParams({ typ: type, subtyp: value });
    }
  };

  const handlePageChange = (
    event: React.MouseEvent<HTMLButtonElement>
  ): void => {
    const target: HTMLButtonElement = event.currentTarget;
    const value = Number(target.value);
    // console.log("val", value);
    setPage(value);
  };

  const handleShowAll = (event: React.MouseEvent<HTMLButtonElement>) => {
    setSearchParams({});
  };

  const handleShowFiltered = (event: React.MouseEvent<HTMLButtonElement>) => {
    setSearchParams({ typ: "1", subtyp: "1" });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setPages(
        Math.ceil(Number((filteredMeasures.length / limit)))
      );
    setPage(1);
  }, [filteredMeasures]);

  useEffect(() => {
    const searchType = Number(searchParams.get("typ"));
    const searchSubType = Number(searchParams.get("subtyp"));
    // console.log("type params", searchType);
    // console.log("subtype params", searchSubType);

    if (searchType && searchSubType) {
      const activeFilter = measureTypes.find((cur) => cur.id === searchType);
      const activeMeasuresArr =
        activeFilter &&
        activeFilter.data.find((cur) => cur.id === searchSubType)?.measures;
      const newState =
        activeMeasuresArr &&
        measures.filter((cur) => activeMeasuresArr.includes(cur.id));
      //   console.log("active measures arr", activeMeasuresArr);
      //   console.log("active articles", newState);
      newState && setFilteredMeasures(newState);
      // console.log("Search type");
    } else {
      setSearchParams({});
      setFilteredMeasures(measures);
    }

    let timer = setTimeout(() => {
      loading && toggleLoading();
    }, 200);
    return () => {
      clearTimeout(timer);
    };
  }, [searchParams, setSearchParams]);



  // useEffect(() => {
  //   window.scrollTo(0, 0);
  //   let timer = setTimeout(() => stopLoading(), 200);
  //   return () => {
  //     clearTimeout(timer);
  //   };
  // }, []);

  const btnsTypeGridItem = measureTypes.map((cur) => {
    return (
      <Grid item xs={12} md={6} className={classes.gridItem} key={cur.id}>
        <Button
          variant="contained"
          color={
            Number(searchParams.get("typ")) === cur.id ? "success" : "secondary"
          }
          onClick={handleTypeButton}
          value={cur.id}
        >
          {cur.name}
        </Button>
      </Grid>
    );
  });

  const btnsSubTypeGridItem = measureTypes.map((measureType) => {
    return (
      measureType.id === Number(searchParams.get("typ")) &&
      measureType.data.map((cur) => {
        return (
          <Grid
            item
            xs={6}
            md={3}
            lg={2}
            className={classes.gridItem}
            key={cur.id}
          >
            <Button
              variant="contained"
              size="small"
              sx={{ height: "100%" }}
              color={
                Number(searchParams.get("subtyp")) === cur.id
                  ? "success"
                  : "secondary"
              }
              onClick={handleSubTypeButton}
              value={cur.id}
            >
              {cur.name}
            </Button>
          </Grid>
        );
      })
    );
  });

  return (
    <>
      <Container maxWidth='xl'>
        <Box sx={width > 768 ? { p: 3 } : { p: 2 }}>
          <Breadcrumb
            childs={[
              { id: 1, name: "Opatření", to: "/opatreni" },
              { id: 2, name: "Výpis" },
            ]}
          />
          <Typography variant="h3" align="center">
            Výpis opatření
          </Typography>
          <Paper elevation={3}>
            <Grid container className={classes.gridContainer}>
              <Grid item xs={12} className={classes.gridItem}>
                <Typography variant="h4" align="center">
                  Aktivní filtry
                </Typography>
              </Grid>
              {/* TOGGLE FILTER ALL */}
              <Grid item xs={6} className={classes.gridItem}>
                <Button
                  variant="contained"
                  onClick={handleShowFiltered}
                  color={searchParams.get("typ") ? "success" : "secondary"}
                  fullWidth
                  size="large"
                >
                  Podrobná filtrace
                </Button>
              </Grid>
              <Grid item xs={6} className={classes.gridItem}>
                <Button
                  variant="contained"
                  onClick={handleShowAll}
                  color={searchParams.get("typ") ? "secondary" : "success"}
                  fullWidth
                  size="large"
                >
                  Zobrazit vše
                </Button>
              </Grid>

              {/* FILTERING BY TYPE */}

              {searchParams.get("typ") && btnsTypeGridItem}
              {searchParams.get("subtyp") && btnsSubTypeGridItem}
              <Grid item xs={12} className={classes.gridItem}>
                <Typography
                  variant="h4"
                  align="center"
                  sx={{ marginTop: "1rem" }}
                >
                  Přehled opatření
                </Typography>
              </Grid>

              {/* MEASURES PREVIEW BY TYPE */}
              <Grid item xs={12} className={classes.gridItem}>
                <MeasuresPreview
                  filteredMeasures={filteredMeasures}
                  page={page}
                  limit={limit}
                />
              </Grid>
              <Grid item xs={12} className={classes.gridItem} sx={{ mt: 1 }}>
                <PaginationControl
                  page={page}
                  handlePageChange={handlePageChange}
                  pages={pages}
                />
              </Grid>
            </Grid>
          </Paper>
        </Box>
      </Container>
      <Footer />
    </>
  );
};

export default MeasuresPreviewPage;
