import React, { FC } from "react";
import { CircularProgress, Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import colors from "../styles/colors";

export const useStyles = makeStyles({
  loadingBox: {
    zIndex: "5000",
    position: "absolute",
    width: "100%",
    height: "100%",
    backgroundColor: colors.white,
  },
  loadingCircle: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: "-30px",
    marginLeft: "-30px",
  },
});

const Loading: FC<{}> = () => {
  const classes = useStyles();
  return (
    <Box className={classes.loadingBox}>
      <CircularProgress
        size={60}
        thickness={4.8}
        color="primary"
        className={classes.loadingCircle}
        disableShrink
      />
    </Box>
  );
};

export default React.memo(Loading);
