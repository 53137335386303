import IMeasureType from "../interface/measureType";

// ******************************** THIS IS PARENT OF MEASURES ********************************* //
// Every measure type has list of measures in current group
// Every group has data which are sub groups

const measureTypes: IMeasureType[] = [
  {
    id: 1,
    name: "Typ rizikové lokality",
    data: [
      {
        id: 1,
        name: "Stanice a zastávky",
        elements: [
          {
            id: 1,
            type: "header",
            content: "Stanice a zastávky",
          },
        ],
        measures: [1,2,3,4,5,6],
      },
      {
        id: 2,
        name: "Každodenní zkratky mimo stanice a zastávky",
        elements: [
          {
            id: 1,
            type: "header",
            content: "Každodenní zkratky mimo stanice a zastávky",
          },
        ],
        measures: [5,6],
      },
      {
        id: 3,
        name: "Turistické stezky a rekreační oblasti",
        elements: [
          {
            id: 1,
            type: "header",
            content: "Turistické stezky a rekreační oblasti",
          },
        ],
        measures: [5,6],
      },
      {
        id: 4,
        name: "Cíle zájmových skupin",
        elements: [
          {
            id: 1,
            type: "header",
            content: "Cíle zájmových skupin",
          },
        ],
        measures: [],
      },
      {
        id: 5,
        name: "Místa setkávání a pobytu sociálně slabších skupin",
        elements: [
          {
            id: 1,
            type: "header",
            content: "Místa setkávání a pobytu",
          },
        ],
        measures: [],
      },
      {
        id: 6,
        name: "Železniční přejezdy",
        elements: [
          {
            id: 1,
            type: "header",
            content: "Železniční přejezdy",
          },
        ],
        measures: [5,6],
      },
    ],
  },
  {
    id: 2,
    name: "Typ opatření",
    data: [
      {
        id: 1,
        name: "Vzdělávací a osvětová",
        elements: [
          {
            id: 1,
            type: "header",
            content: "Osvětová a vzdělávací",
          },
        ],
        measures: [],
      },
      {
        id: 2,
        name: "Technologická",
        elements: [
          {
            id: 1,
            type: "header",
            content: "Technologická",
          },
        ],
        measures: [1,2,3,4,5,6],
      },
      {
        id: 3,
        name: "Stavební",
        elements: [
          {
            id: 1,
            type: "header",
            content: "Stavební",
          },
        ],
        measures: [],
      },
      {
        id: 4,
        name: "Terénní a zahradnické úpravy",
        elements: [
          {
            id: 1,
            type: "header",
            content: "Terénní a zahradnické úpravy",
          },
        ],
        measures: [],
      },
      {
        id: 5,
        name: "Dozor a dohled",
        elements: [
          {
            id: 1,
            type: "header",
            content: "Dozor a dohled",
          },
        ],
        measures: [],
      },
    ],
  },
];

export default measureTypes;
