import { FC } from "react";
import { renderElement } from "../functions/render";


import IElement from "../interface/element";



interface IDetailProps {
  elements: IElement[];
}

const Detail: FC<IDetailProps> = ({ elements }) => {


  const childs = elements.map((element: IElement) => {
    return renderElement(element);
  });

  return <>{childs}</>;
};

export default Detail;
