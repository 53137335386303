import React, { FC, memo } from "react";
import { Drawer, Box, Typography, Button, IconButton } from "@mui/material";
import { NavLink } from "react-router-dom";

import { makeStyles } from "@mui/styles";
import colors from "../styles/colors";
import { paths } from "../config/routes";

import MenuIcon from "@mui/icons-material/Menu";
import HomeIcon from "@mui/icons-material/Home";
import InfoIcon from "@mui/icons-material/Info";
import TrainIcon from "@mui/icons-material/Train";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import CloseIcon from "@mui/icons-material/Close";

const useStyles = makeStyles({
  drawer: {
    width: "100%",
    "@media (min-width: 350px)": {
      width: "300px",
    },
  },
  link: {
    textDecoration: "none",
    color: colors.primary,
    "&:hover": {
      color: colors.primary,
    },
    padding: "1.2rem 2rem 0 2rem",
  },
  activeLink: {
    textDecoration: "none",
    color: colors.green,
    padding: " 1.2rem 2rem 0 2rem",
  },
  closeButton: {
    position: "absolute",
    top: "0.3rem",
    right: "0.3rem",
  },
});

interface ISidebarProps {
  isOpenSidebar: boolean;
  toggleSidebar: () => void;
}

const Sidebar: FC<ISidebarProps> = ({ isOpenSidebar, toggleSidebar }) => {
  const classes = useStyles();
  // console.log("re render sidebar");
  return (
    <Drawer anchor="right" open={isOpenSidebar} onClose={toggleSidebar}>
      <IconButton className={classes.closeButton} onClick={toggleSidebar}>
        <CloseIcon />
      </IconButton>
      <NavLink
        to={paths.homePage}
        className={(navData) =>
          navData.isActive ? classes.activeLink : classes.link
        }
        style={{ marginTop: "2rem" }}
        onClick={toggleSidebar}
      >
        <Button color="inherit" startIcon={<HomeIcon />}>
          Domů
        </Button>
      </NavLink>
      <NavLink
        to={paths.measuresPage}
        className={(navData) =>
          navData.isActive ? classes.activeLink : classes.link
        }
        onClick={toggleSidebar}
      >
        <Button color="inherit" startIcon={<TrainIcon />}>
          Opatření
        </Button>
      </NavLink>

      <NavLink
        to={paths.aboutPage}
        className={(navData) =>
          navData.isActive ? classes.activeLink : classes.link
        }
        onClick={toggleSidebar}
      >
        <Button color="inherit" startIcon={<InfoIcon />}>
          O metodice
        </Button>
      </NavLink>
    </Drawer>
  );
};

// export default memo(Sidebar);
export default Sidebar;
