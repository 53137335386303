import { FC } from "react";
import PaperPreview from "../../../components/PaperPreview";
import IMeasure from "../../../interface/measure";
import useCurrentWidth from "../../../hooks/useCurrentWidth";

interface IMeasuresPreviewProps {
  filteredMeasures: IMeasure[];
  page: number;
  limit: number;
}

const MeasuresPreview: FC<IMeasuresPreviewProps> = ({
  filteredMeasures,
  page,
  limit,
}) => {
  const width = useCurrentWidth();
  const start = --page * limit;
  const end = start + limit;

  const preview = filteredMeasures.slice(start, end).map((measure) => {
    const elements = measure.elements;

    const firstheader = elements.find((cur) => cur.type === "header");
    const firstPara = elements.find((cur) => cur.type === "paragraph");

    return (
      <PaperPreview
        heading={firstheader ? firstheader.content : ""}
        text={firstPara ? firstPara.content : ""}
        gotoText={"Číst více..."}
        key={measure.id}
        id={measure.id}
      />
    );
  });

  return <>{preview}</>;
};

export default MeasuresPreview;
