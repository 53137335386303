const colors = {
  secondary: "#D3D3D3",
  warning: "#f3742e",
  primary: "#0e4c85",
  green: "#00ab00",
  purple: "#801459",
  yellow: "#f7c900",
  lightBlue: "#297dc1",
  pink: "#ffc0cb",
  lightGreen: "#bfcc14",
  red: "#d12624",
  brightGray: "#ebecf0",
  black: "#000000",
  gray: "#484c4c",
  white: "#ffffff",
  textGray: "#58595b",
  lightBlueOpa: "rgba(41, 125, 193, $0.5)",
  greenOpa: "rgba(0, 171, 0, 0.5)",
  lightGreenOpa: "rgba(191, 204, 20, $0.5)",
  yellowOpa: "rgba(247, 201, 0, 0.5)",
  redOpa: "rgba(209, 38, 36, 0.5)",
  purpleOpa: "rgba(128, 20, 89, 0.5)",
  brightGrayOpa: "rgba(235, 236, 240, 0.5)",
  blackOpa: "rgba(0, 0, 0, 0.5)",
  grayOpa: "rgba(72, 76, 76, 0.5)",
  primaryOpa: "rgba(14, 76, 133, 0.5)",
  secondaryOpa: "rgba(243, 116, 46, 0.5)",
};

export default colors;
