import { FC, memo } from "react";
import { Button, Grid, Typography, Paper, Box } from "@mui/material";
import measureTypes from "../data/measureTypes";
import { makeStyles } from "@mui/styles";
import { NavLink } from "react-router-dom";
import { paths } from "../config/routes";
import { generateArrFromNum } from "../functions/functions";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

const useStyles = makeStyles({});
interface IPaginationControlProps {
  page: number;
  pages: number;
  handlePageChange: any;
}

const PaginationControl: FC<IPaginationControlProps> = ({
  page,
  pages,
  handlePageChange,
}) => {
  const classes = useStyles();
  // console.log("Re render pagination control");

  const chevronLeftBtn = (
    <Button
      value={1}
      onClick={handlePageChange}
      disabled={page === 1 ? true : false}
    >
      <ChevronLeftIcon />
    </Button>
  );
  const chevronRightBtn = (
    <Button
      value={page + 1}
      onClick={handlePageChange}
      disabled={page === pages ? true : false}
    >
      <ChevronRightIcon />
    </Button>
  );

  const control =
    pages > 1 ? (
      <Box sx={{ width: "100%", display: "flex", justifyContent: "flex-end" }}>
        {chevronLeftBtn}
        <Typography variant="body2" sx={{ marginTop: "0.5rem" }}>
          {page} z {pages}
        </Typography>
        {chevronRightBtn}
      </Box>
    ) : (
      ""
    );

  return <>{control}</>;
};

// export default memo(PaginationControl);
export default PaginationControl;
