import React, { FC, useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import routes from "./config/routes";
import Navbar from "./components/Navbar";
import Sidebar from "./components/Sidebar";
import Loading from "./components/Loading";
import "react-image-gallery/styles/css/image-gallery.css";
import "./styles/App.css";

const App: FC<{}> = () => {
  // Sidebar
  const [isOpenSidebar, setIsOpenSidebar] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);

  const toggleLoading = (): void => {
    setLoading((prev) => !prev);
  };

  const toggleSidebar = (): void => {
    setIsOpenSidebar((prev) => !prev);
  };

  // if (isLoading) return <Loading />;

  return (
    <BrowserRouter>
      {loading && <Loading />}
      <Navbar toggleSidebar={toggleSidebar} />
      <Sidebar isOpenSidebar={isOpenSidebar} toggleSidebar={toggleSidebar} />
      <Routes>
          {routes.map((route, index) => {
            return (
              <Route
                key={index}
                path={route.path}
                element={
                  <route.element
                    toggleLoading={toggleLoading}
                    loading={loading}
                  />
                }
              />
            );
          })}
        
      </Routes>
    </BrowserRouter>
  );
};

export default App;
