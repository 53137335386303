import IMeasure from "../interface/measure";
import { Link } from "@mui/material";

// ************************* LIST OF ALL MEASURES ************************************
// IDS ARE IN measuretypes.tsx also in the data folder
const measures: IMeasure[] = [
  {
    id: 1,
    name: "Informační a orientační systém",
    elements: [
      {
        id: 1,
        type: "header",
        content: "Informační a orientační systém",
      },
      {
        id: 2,
        type: "paragraph",
        content: <>Jako prevenci trespassingu na větších nádražích můžeme chápat i kvalitní informační a orientační systém. Přehlednost a včasné informace přispívají nejen ke komfortu cestujících, ale pomáhají předcházet nebezpečným situacím a rizikovému chování plynoucímu z dezorientace a nedostatku času. </>
      },
      {
        id: 3,
        type: "paragraph",
        content: <>Kromě zvýšení bezpečnosti dopravní je usnadnění orientace zároveň i prevencí kriminality. Cestující, který je soustředěn na obtížné hledání správné cesty nebo zabloudil, je totiž snadnějším cílem kapsářů, případně i pachatelů závažnější trestné činnosti.  Situaci může výrazně zlepšit ošetření zdánlivých samozřejmostí</>
          
      },
      {
        id: 4,
        type: "list",
        content: [
          {
            id: 1,
            type: "item",
            content: <>Pro přijíždějící pasažéry je důležité, aby měli možnost včas zjistit <b>název zastávky</b> či stanice, zejména pokud ve vlaku nejsou zastávky hlášeny, a mohli se řádně připravit k výstupu. Proto je třeba se ujistit, že tabule s názvem stanice jsou umístěny a udržovány tak, aby byly snadno čitelné i z přijíždějícího vlaku.</>
          },
          {
            id: 2,
            type: "item",
            content: <>Pro prevenci rizikového pohybu mezi nástupišti jsou zásadní jasné a včasné <b>informace o odjezdech vlaků</b>. Informační tabule s odjezdy je v této souvislosti žádoucí situovat tak, aby je mohli sledovat nejen příchozí cestující, ale i přestupující.</>
          },
          {
            id: 3,
            type: "item",
            content: <>Součástí opatření pro dobrou orientaci je i <b>zřetelné označení nástupišť</b>. V případě ČR může být pro méně zkušené, méně zdatné nebo stresované cestující i pro zahraniční návštěvníky problémem systém nástupních hran (nástupiště a kolej, namísto jediného jasného údaje); tím důležitější je výrazné, dobře čitelné označení.</>
          },
          {
            id: 4,
            type: "item",
            content: <>Orientaci napomohou dobře umístěné <b>směrové piktogramy</b>, ve větších stanicích případně i <b>plánky.</b></>
          },
          
        ],
      },
      {
        id: 5,
        type: "paragraph",
        content: <>Pro detailní informace o možnostech a podmínkách zkvalitnění informačního a orientačního systému viz <Link target="_blank" href="https://www.spravazeleznic.cz/stavby-zakazky/podklady-pro-zhotovitele/graficke-podklady">v následujícím odkazu</Link>.</>
          
      },

    ],
  },
  {
    id: 2,
    name: "Vymezení bezpečného odstupu od okraje nástupiště",
    elements: [
      {
        id: 1,
        type: "header",
        content: "Vymezení bezpečného odstupu od okraje nástupiště",
      },
      {
        id: 2,
        type: "paragraph",
        content: <>Nehody v důsledku nedostatečného odstupu končí zpravidla tragicky. Osoba stojí příliš blízko okraje nástupiště a je zachycena projíždějícím vlakem, resp. stržena proudem vzduchu. Jestliže je hranice bezpečného odstupu alespoň vyznačena barvou, je třeba dbát na její pravidelnou obnovu. Toto řešení nicméně není příliš účinné.</>
      },
      {
        id: 3,
        type: "pictures",
        name: "Nerespektování bezpečného odstupu",
        content: [
          {
            id: 1,
            src: "2/obr-1.jpg"
          }
        ],
      },
      {
        id: 4,
        type: "paragraph",
        content: <>Lidé na nástupišti si nutnost udržovat odstup často neuvědomují a upozornění by tedy mělo být co nejdůraznější. Napomoci může odlišení nebezpečného pásma nejen barvou, ale i strukturou povrchu, formou hmatného varovného pásu nebo vodicí linie. Takové řešení je vhodné i s ohledem na nevidomé cestující. Opatření je možné doplnit <Link href="/opatreni/detail/6">výstražnými nápisy nebo piktogramy</Link>.</>
          
      },
      {
        id: 5,
        type: "pictures",
        name: "Brno Řečkovice: Vyznačení hranice nebezpečného pásma formou vodicí linie s funkcí varovného pásu",
        content: [
          {
            id: 1,
            src: "2/obr-2.jpg"
          },
          {
            id: 2,
            src: "2/obr-3.jpg",
          }
        ],
      },
      {
        id: 6,
        type: "pictures",
        name: "Amersfoort, Nizozemí; York, Velká Británie: Provedení celého okraje nástupiště materiálem s odlišnou strukturou povrchu",
        content: [
          {
            id: 1,
            src: "2/obr-4.jpg"
          },
          {
            id: 2,
            src: "2/obr-5.jpg"
          }
        ],
      },
      {
        id: 7,
        type: "pictures",
        name: "Německo: Zvýraznění bezpečného odstupu odlišnou barvou a materiálem",
        content: [
          {
            id: 1,
            src: "2/obr-6.jpg"
          },
          {
            id: 2,
            src: "2/obr-7.jpg"
          }
        ],
      },
      {
        id: 8,
        type: "pictures",
        name: "Výstražná značka, varující před porušením bezpečného odstupu, užívaná v Německu",
        content: [
          {
            id: 1,
            src: "2/obr-8.jpg",
          },
          {
            id: 2,
            src: "2/obr-9.jpg",
          }
        ],
      },
      {
        id: 9,
        type: "paragraph",
        content: <>V zahraničí se setkáme i s dalšími řešeními, například vyznačení okraje barevnými světly nebo i s jediným skutečně účinným řešením, tedy úplným uzavřením nástupiště průhlednými posuvnými panely, které se otevírají jen po zastavení vlaku. Toto řešení je typické spíše pro podzemní dráhy.</>
      },
      {
        id: 10,
        type: "paragraph",
        content: <>Nedodržování bezpečného odstupu je obtížně řešitelné. Zřetelné odlišení okraje nástupiště napomůže chodcům, kteří si riziko uvědomují, a je důležitým vodítkem pro nevidomé. Protože je však přístup k samotnému okraji perónu fyzicky možný (není-li uzavřen posuvnými panely), některé cestující, zejména děti, ani sebevýraznější značení neudrží v bezpečném pásmu. V případě tohoto rizikového chování je na místě využít i opatření <Link href="/opatreni/detail/7">vzdělávacích a osvětových</Link>.</>
      },
      {
        id: 11,
        type: "pictures",
        name: "Španělsko: Provedení okraje nástupiště signálním pásem a s využitím zelených světel (zdroj: UIC) ",
        content: [
          {
            id: 1,
            src: "2/obr-10.jpg",
          }
        ],
      },
      {
        id: 12,
        type: "pictures",
        name: "Další příklady provedení signálního pásu: USA, Švýcarsko",
        content: [
          {
            id: 1,
            src: "2/obr-11.jpg",
          },
          {
            id: 1,
            src: "2/obr-12.jpg",
          }
        ],
      },


    ],
  },
  {
    id: 3,
    name: "Plot mezi kolejemi",
    elements: [
      {
        id: 1,
        type: "header",
        content: "Plot mezi kolejemi",
      },
      {
        id: 2,
        type: "paragraph",
        content: <>Opatření můžeme v odůvodněných případech použít na nádražích, kde jsou mezi nástupišti dvě nebo více kolejí, pokud je mezi nimi dostatečný prostor. Plot znesnadňuje chodcům nelegální přebíhání mezi nástupišti.</>
      },
      {
        id: 3,
        type: "list",
        content: [
          {
            id: 1,
            type: "item",
            content: <>Je třeba pamatovat na zabudování zabezpečené branky pro služební účely nebo potřebu nouzového úniku.</>
          },
          {
            id: 2,
            type: "item",
            content: <>Plot je možno doplnit varovnými <Link href="/opatreni/detail/6">značkami a nápisy.</Link></>
          },
          {
            id: 3,
            type: "item",
            content: <>Stejně jako u dalších opatření zabraňujících v nelegálním přecházení je nutné nabídnout chodcům dostatečně atraktivní legální alternativu.</>
          },
          {
            id: 4,
            type: "item",
            content: <>Umístění opatření předpokládá předběžný pozorovací průzkum chování chodců, aby splnilo svůj účel a bránilo trespassingu v místě, kde k němu skutečně dochází. Plot by měl být dostatečně dlouhý, aby nelákal k obcházení, kterým se riziko střetu ještě zvyšuje. Zároveň je třeba pravidelně kontrolovat, jestli si chodci nevytvořili novou nelegální zkratku.</>
          },
          
        ],
      },
      {
        id: 4,
        type: "pictures",
        name: "Německo: umístění plotu mezi kolejemi ve stanici",
        content: [
          {
            id: 1,
            src: "3/obr-1.jpg",
          },
          {
            id: 2,
            src: "3/obr-2.jpg",
          }
        ],
      },
      {
        id: 5,
        type: "pictures",
        name: "Plot mezi kolejemi ve stanici: příklad z USA",
        content: [
          {
            id: 1,
            src: "3/obr-3.jpg",
          },
          {
            id: 2,
            src: "3/obr-4.jpg",
          }
        ],
      },
    ]
  },
  {
    id: 4,
    name: "Plot/zábradlí na konci nástupiště",
    elements: [
      {
        id: 1,
        type: "header",
        content: "Plot/zábradlí na konci nástupiště",
      },
      {
        id: 2,
        type: "paragraph",
        content: <>Na konci nástupiště lze umístit plot nebo zábradlí, které má za úkol zabránit v seskakování přímo do kolejiště nebo do prostoru přejezdu ve výstraze. </>
      },
      {
        id: 3,
        type: "list",
        content: [
          {
            id: 1,
            type: "item",
            content: <>Stejně jako u jiných opatření zabraňujících v nelegálním přecházení je důležité nabídnout chodcům dostatečně atraktivní legální alternativu přecházení.</>
          },
          {
            id: 2,
            type: "item",
            content: <>Plot zpravidla nemůže dosahovat až k okrajům nástupiště kvůli narušení průjezdného průřezu; aby nedocházelo k obcházení, je možné plot doplnit např. instalací <Link href="/opatreni/detail/5">neschůdných rohoží.</Link></>
          },
          {
            id: 3,
            type: "item",
            content: <>Podle umístění opatření je třeba posoudit zabudování zabezpečené branky pro služební účely nebo potřebu nouzového úniku.</>
          },
          {
            id: 4,
            type: "item",
            content: <>Plot je vhodné doplnit varovnými <Link href="/opatreni/detail/6">značkami nebo nápisy</Link>.</>
          },
          
        ],
      },
      {
        id: 4,
        type: "pictures",
        name: "Brno – Řečkovice: zábradlí na konci nástupiště doplněné brankou pro služební účely",
        content: [
          {
            id: 1,
            src: "4/obr-1.jpg",
          },
          {
            id: 2,
            src: "4/obr-2.jpg",
          }
        ],
      },
      {
        id: 5,
        type: "pictures",
        name: "Dyje: zábradlí na konci nástupiště doplněné neschůdnou rohoží",
        content: [
          {
            id: 1,
            src: "4/obr-3.jpg",
          }
        ],
      },
    ]
  },
  {
    id: 5,
    name: "Neschůdné rohože",
    elements: [
      {
        id: 1,
        type: "header",
        content: "Neschůdné rohože",
      },
      {
        id: 2,
        type: "paragraph",
        content: <>Neschůdné rohože (známé taky pod názvy nepochozí panely, anti-trespass panels, ATP) jsou jednoduchým vodorovným zařízením, které slouží k odrazení chodců a zásadnímu znesnadnění jejich pohybu v místech veřejnosti nepřístupných. Jde o panely s obtížně schůdným povrchem, které jsou v potřebné sestavě položeny v místech neoprávněného vstupu chodců. Jejich účinnost je v případě dodržení všech zásad pro použití poměrně vysoká.</>
      },
      {
        id: 3,
        type: "paragraph",
        content: <>Plot je vhodné doplnit varovnými <Link href="/opatreni/detail/4">zábradlí/plotu</Link> v místech, kde nemůže být aplikováno kvůli zajištění průjezdného průřezu. Obecně lze užít všude, kde existuje přirozené nebo umělé ohraničení rizikového prostoru. Místa typického užití jsou:</>
      },
      {
        id: 4,
        type: "list",
        content: [
          {
            id: 1,
            type: "item",
            content: <>Nástupiště: zejména jako doplněk <Link href="/opatreni/detail/4">plotu/zábradlí na konci nástupiště</Link>, pro zabránění přímého vstupu z nástupiště do kolejiště, přebíhání mezi peróny</>
          },
          {
            id: 2,
            type: "item",
            content: <>Železniční přejezdy: pro prevenci obcházení přejezdu ve výstraze, vstupu na přejezd z míst, kde není vidět na výstražník anebo odkud není řádný rozhled na trať, zabránění vstupu z přejezdu na nelegální cestu v kolejišti</>
          },
          {
            id: 3,
            type: "item",
            content: <>Nelegální přístupové cesty k železničním tunelům a mostům</>
          },
          {
            id: 4,
            type: "item",
            content: <>Ve stanicích jako prevence vstupu do míst veřejnosti nepřístupných, ochrana před úrazem, vandalstvím a krádežemi</>
          },
          {
            id: 5,
            type: "item",
            content: <>Umístění na mostní zábradlí (místo ostnatého drátu nebo bodců) jako prevence sebevražd</>
          },
        ],
      },
      {
        id: 5,
        type: "paragraph",
        content: <>Rohože existují v rozličných provedeních. Nejznámější je profil jehlanů či kuželů stejné nebo proměnlivé výšky, často se používá i vlnitý profil. Materiálem může být pryžový recyklát, dřevo nebo sklolaminát. Na trhu jsou již i panely z materiálů zpomalujících hoření. Od použitého materiálu se odvíjí hmotnost jednotlivých panelů (cca 25 – 95 kg) a tedy i snadnost manipulace a montáže, a životnost (cca 5 – 25 let). Také rozměry panelů nejsou jednotné, délky standardního panelu se pohybují od 0,9 m po 1,4 m.</>
      },
      {
        id: 6,
        type: "paragraph",
        content: <>Panely jsou variabilní, lze je upravit do různých tvarů a velikostí nebo opatřit výřezy. Samy o sobě nevyžadují zvláštní údržbu a při provádění složitějších prací na trati není obtížné jednotlivé panely odmontovat a po skončení umístit zpět. Při dodržení zásad bezpečnosti práce nepředstavují riziko pro zaměstnance.</>
      },
      {
        id: 7,
        type: "list",
        content: [
          {
            id: 1,
            type: "item",
            content: <>Stejně jako u ostatních opatření pro zabránění vstupu je účinnost podmíněna existencí vhodné a dostatečně atraktivní infrastruktury pro chodce. </>
          },
          {
            id: 2,
            type: "item",
            content: <>Hlavní podmínkou instalace je naprosté vyloučení možnosti opatření obejít – rohože by tedy měly dosahovat až k existujícímu ohraničení (zeď, plot, živý plot apod.) nebo by ohraničení mělo být doplněno, případně by panely měly být v takové pozici, aby obcházení nebylo pro uživatele zajímavé.</>
          },
          {
            id: 3,
            type: "item",
            content: <>Opatření má být dobře viditelné, kvůli efektu odrazení od nelegálního přecházení i jako prevence rizika zakopnutí a zranění.</>
          },
          {
            id: 4,
            type: "item",
            content: <>Při instalaci je třeba brát v úvahu prvky, které by mohly s panely kolidovat a zároveň chodcům pomoci překonat neschůdnou rohož (zábradlí, terénní nerovnosti, technické vybavení železnice).</>
          }
        ],
      },
      {
        id: 8,
        type: "pictures",
        name: "Dyje, ČR: příklad umístění neschůdné rohože na kraji nástupiště jako doplněk zábradlí, pro prevenci přímého vstupu z perónu na přejezd ve výstraze",
        content: [
          {
            id: 1,
            src: "5/obr-1.jpg",
          },
          {
            id: 2,
            src: "5/obr-2.jpg",
          }
        ],
      },
      {
        id: 9,
        type: "pictures",
        name: "Velká Británie: umístění ATP na okraji nástupiště jako doplněk zábradlí; umístění na přejezdu",
        content: [
          {
            id: 1,
            src: "5/obr-3.jpg",
          },
          {
            id: 2,
            src: "5/obr-4.jpg",
          }
        ],
      },
      {
        id: 10,
        type: "pictures",
        name: "Paříž, Francie: umístění ATP mezi přejezd a konce nástupišť pro prevenci vstupu na přejezd ve výstraze",
        content: [
          {
            id: 1,
            src: "5/obr-5.jpg",
          },
          {
            id: 2,
            src: "5/obr-6.jpg",
          }
        ],
      },
      {
        id: 11,
        type: "pictures",
        name: "Amersfoort, Nizozemí: umístění neschůdných rohoží vedle přejezdu pro prevenci vstupu do míst veřejnosti nepřístupných",
        content: [
          {
            id: 1,
            src: "5/obr-7.jpg",
          },
          {
            id: 2,
            src: "5/obr-8.jpg",
          },
          {
            id: 3,
            src: "5/obr-9.jpg",
          },
          {
            id: 4,
            src: "5/obr-10.jpg",
          }
        ],
      },
      {
        id: 12,
        type: "paragraph",
        content: <>Podrobnosti způsobu a podmínek užití neschůdných rohoží jsou uvedeny v <Link href="https://amelia.cdvinfo.cz/file/prirucka-pro-uziti-neschudnych-rohozi-pro-prevenci-neopravnenych-vstupu-osob-do-prostoru-drahy/" target="_blank">Příručce pro užití neschůdných rohoží pro prevenci neoprávněných vstupů osob do prostoru dráhy</Link> nebo na <Link href="https://www.shopcdv.cz/cs/prirucka-pro-uziti-neschudnych-rohozi-pro-prevenci-neopravnenych-vstupu-osob-do-prostoru-drahy" target="_blank">e-shopu CDV</Link>.</>
      },
    ]
  },
  {
    id: 6,
    name: "Varovné nápisy a značky",
    elements: [
      {
        id: 1,
        type: "header",
        content: "Varovné nápisy a značky",
      },
      {
        id: 2,
        type: "paragraph",
        content: <>Nápisy a piktogramy označující hranici nebezpečného pásma nebo zákaz vstupu jsou především doplňkovým opatřením; samy o sobě bývají spíše ignorovány. Jejich úspěšnost závisí na provedení, umístění i údržbě, tak, aby si jich chodci vůbec všimli a pochopili jejich význam. Ani nápadná značka se zjevným významem, umístěná v zorném poli uživatelů, však bez dalších opatření zpravidla nestačí zabránit vstupu trespasserů.</>
      },
      {
        id: 3,
        type: "paragraph",
        content: <>Sdělení na značkách by mělo být jednoznačné a snadno pochopitelné, ať už se jedná o text, nebo piktogram. Napomoci může i volba barev; žlutá a červená jsou asociovány s nebezpečím a vzbudí ostražitost.</>
      },
      {
        id: 4,
        type: "paragraph",
        content: <>Důležitá je dobrá viditelnost a čitelnost (údržba, velikost, typ písma). S tím souvisí i vhodné umístění tak, aby chodec na tabulku jednak viděl, jednak byl upozorněn na nebezpečí nebo varován před určitým chováním v relevantním místě. Zašlá tabulka, umístěná vedle úrovňového přechodu pro zaměstnance několik metrů od výchozího místa a drobným písmem upozorňující na zákaz vstupu veřejnosti, pravděpodobně nesplní svůj účel.</>
      },
      {
        id: 5,
        type: "paragraph",
        content: <>Značky nebo tabulky by měly být umisťovány jen v odůvodněných případech, nemělo by jich být mnoho, jinak je chodec přestává vnímat.</>
      },
      {
        id: 6,
        type: "paragraph",
        content: <>Využití varovných značek a nápisů:</>
      },
      {
        id: 7,
        type: "list",
        content: [
          {
            id: 1,
            type: "item",
            content: <>Piktogram zakazující vstup nepovolaným osobám na konci perónu, často jako doplněk zábradlí</>
          },
          {
            id: 2,
            type: "item",
            content: <>Piktogram nebo nápis zakazující vstup nepovolaným osobám vedle přechodu pro zaměstnance</>
          },
          {
            id: 3,
            type: "item",
            content: <>Nápis nabádající k opatrnosti vedle úrovňového přechodu pro cestující</>
          },
          {
            id: 4,
            type: "item",
            content: <>Nápis nebo piktogram varující před vstupem do nebezpečného pásma, např. na okraji nástupiště nebo v blízkosti technického zázemí</>
          },
          {
            id: 5,
            type: "item",
            content: <>Piktogram nebo nápis varující před vstupem na přejezd ve výstraze nebo zakazující pohyb chodců mimo přejezd</>
          },
          {
            id: 6,
            type: "item",
            content: <>Piktogramy a nápisy zakazující vstup nebo varující před rizikem v místech častého nelegálního vstupu chodců na drážní infrastrukturu</>
          }
        ],
        
      },
      {
        id: 8,
        type: "paragraph",
        content: <>Podmínky umístění a provedení výstražných a zákazových tabulek v ČR jsou <Link href="https://www.spravazeleznic.cz/stavby-zakazky/podklady-pro-zhotovitele/graficke-podklady" target="_blank">k nahlédnutí zde:</Link> </>
      },
      {
        id: 9,
        type: "subheader",
        content: "Příklady provedení konce nástupiště"
      },
      {
        id: 10,
        type: "pictures",
        name: "Rakousko",
        content: [
          {
            id: 1,
            src: "6/obr-1.jpg",
          },
          {
            id: 2,
            src: "6/obr-2.jpg",
          }
        ],
      },
      {
        id: 11,
        type: "pictures",
        name: "Rakousko, Německo",
        content: [
          {
            id: 1,
            src: "6/obr-3.jpg",
          },
          {
            id: 2,
            src: "6/obr-4.jpg",
            name: "Rakousko, Německo"
          }
        ],
      },
      {
        id: 12,
        type: "pictures",
        name: "Česká republika",
        content: [
          {
            id: 1,
            src: "6/obr-5.jpg"
          },
          {
            id: 2,
            src: "6/obr-6.jpg"
          },
          {
            id: 3,
            src: "6/obr-7.jpg"
          }
        ],
      },
      {
        id: 13,
        type: "subheader",
        content: "Příklady varování před pohybem na okraji nástupiště"
      },
      {
        id: 14,
        type: "pictures",
        name: "Rakousko, Německo",
        content: [
          {
            id: 1,
            src: "6/obr-8.jpg",
          },
          {
            id: 2,
            src: "6/obr-9.jpg",
          }
        ],
      },
      {
        id: 15,
        type: "pictures",
        name: "Spojené státy americké",
        content: [
          {
            id: 1,
            src: "6/obr-10.jpg",
          }
        ],
      },
      {
        id: 16,
        type: "subheader",
        content: "Příklady zákazu přecházení kolejí"
      },
      {
        id: 17,
        type: "pictures",
        name: "Spojené státy americké, Rakousko",
        content: [
          {
            id: 1,
            src: "6/obr-11.jpg",
          },
          {
            id: 2,
            src: "6/obr-12.jpg",
          }
        ],
      },
      {
        id: 18,
        type: "pictures",
        name: "Varování před vstupem na přejezd ve výstraze („Dokud nezhasne červené světlo, může přijet další vlak“), Nizozemí",
        content: [
          {
            id: 1,
            src: "6/obr-13.jpg",
          }
        ],
      },
      {
        id: 19,
        type: "pictures",
        name: "Příklady různých výstražných a zákazových tabulek, USA",
        content: [
          {
            id: 1,
            src: "6/obr-14.jpg",
          }
        ],
      }

      
    ]
  },
  

 
  // {
  //   id: 7,
  //   name: "Vzdělávací aktivity",
  //   elements: []
  // },

];

export default measures;
