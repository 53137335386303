import { FC } from "react";
import {
  BadgePropsColorOverrides,
  Breadcrumbs,
  Button,
  Typography,
} from "@mui/material";
import { NavLink } from "react-router-dom";
import HomeIcon from "@mui/icons-material/Home";
import { paths } from "../config/routes";
import { makeStyles } from "@mui/styles";
import colors from "../styles/colors";
const useStyles = makeStyles({
  link: {
    color: colors.primary,
    textDecoration: "none",
    "&:hover": {
      color: colors.primary,
    },
  },
  homeIcon: {
    fontSize: "1.5rem",
    position: "relative",
    top: "0.1rem",
  },
});
interface IProps {
  id: number;
  name: string;
  to?: string;
}

interface IBreadcrumbProps {
  childs: IProps[];
}

const Breadcrumb: FC<IBreadcrumbProps> = ({ childs }) => {
  const classes = useStyles();

  return (
    <Breadcrumbs aria-label="breadcrumb">
      <NavLink to={paths.homePage} className={classes.link}>
        <HomeIcon className={classes.homeIcon} />
      </NavLink>
      {childs.map((cur) =>
        cur.to ? (
          <NavLink key={cur.id} to={cur.to} className={classes.link}>
            <Typography variant="body1">{cur.name}</Typography>
          </NavLink>
        ) : (
          <Typography key={cur.id} variant="body1" sx={{ color: colors.gray }}>
            {cur.name}
          </Typography>
        )
      )}
    </Breadcrumbs>
  );
};

export default Breadcrumb;
