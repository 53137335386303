import IRoute from "../interface/route";
import HomePage from "../pages/home/HomePage";
import ErrorPage from "../pages/error/ErrorPage";
import AboutPage from "../pages/about/AboutPage";
import MeasuresPage from "../pages/measures/MeasuresPage";
import MeasuresPreviewPage from "../pages/measures/MeasuresPreviewPage";
import MeasureDetailPage from "../pages/measures/MeasureDetailPage";

export const paths = {
  homePage: "/",
  aboutPage: "/o-metodice",
  measuresPage: "/opatreni",
  measuresPreviewPage: "/opatreni/vypis",
  measureDetailPage: "/opatreni/detail",
  errorPage: "*",
};

const routes: IRoute[] = [
  {
    path: paths.homePage,
    name: "Home Page",
    element: HomePage,
  },
  {
    path: paths.aboutPage,
    name: "About Page",
    element: AboutPage,
  },
  {
    path: paths.measuresPage,
    name: "Measures Page",
    element: MeasuresPage,
  },
  {
    path: paths.measuresPreviewPage,
    name: "Measures Preview Page",
    element: MeasuresPreviewPage,
  },
  {
    path: `${paths.measureDetailPage}/:id`,
    name: "Measures Detail Page",
    element: MeasureDetailPage,
  },
  // {
  //   path: paths.errorPage,
  //   name: "Error Page",
  //   element: <ErrorPage />,
  // },
];

export default routes;
