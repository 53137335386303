import colors from "./colors";
import { createTheme } from "@mui/material/styles";
// CDV Colors and Bootstrap breakpoints
const muiCustomTheme = createTheme({
  typography: {
    h3: {
      margin: "2rem 0 2rem 0",
      // textTransform: "uppercase",
    },
    h4: {
      marginBottom: "2rem",
      marginTop: "2rem"
    },
    h5: {
      marginBottom: "2rem",
      marginTop: "2rem",
      fontWeight: "bold",
    },
    body1: {
      color: colors.textGray,
    },
    body2: {
      color: colors.textGray,
    },
  },
  palette: {
    primary: {
      main: colors.primary,
      contrastText: colors.white,
    },
    secondary: {
      main: colors.secondary,
      contrastText: colors.black,
    },
    info: {
      main: colors.lightBlue,
      contrastText: colors.white,
    },
  },

  breakpoints: {
    values: {
      xs: 0,
      sm: 576,
      md: 768,
      lg: 992,
      xl: 1400,
    },
  },
});

export default muiCustomTheme;
