import { FC, useEffect } from "react";
import { Typography, Link, Grid, Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import colors from "../styles/colors";
import FacebookIcon from "@mui/icons-material/Facebook";
import YouTubeIcon from "@mui/icons-material/YouTube";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import useCurrentWidth from "../hooks/useCurrentWidth";

const useStyles = makeStyles({
  footer: {
    marginTop: "4rem",
    color: colors.white,
    zIndex: 1000,
  },
  link: {
    color: colors.white,
    textDecoration: "none",
    "&:hover": {
      color: colors.warning,
    },
  },
  logo: {
    height: "50px",
    background: colors.white,
    borderRadius: "0.6rem",
    border: "none",
  },
  logoLink: {
    display: "flex",
    justifyContent: "center",
  },

  icon: {
    fontSize: "2rem",
    margin: "0 0.4rem 0 0.4rem",
  },
  flexCenter: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "start",
    alignItems: "center",
  },
  bgLightBlue: {
    background: colors.lightBlue,
    color: colors.white,
  },
  bgDarkBlue: {
    background: colors.primary,
    color: colors.white,
  },
  header: {
    marginBottom: "1rem",
    marginTop: "1.5rem",
  },
});

const Footer: FC<{}> = () => {
  const classes = useStyles();
  const width = useCurrentWidth();
  // console.log("FOOTER!!!!!!!!!!!!!");


  return (
    <footer className={classes.footer}>
      <Grid container>
        <Grid
          item
          xs={12}
          sm={6}
          md={4}
          className={classes.flexCenter}
          sx={{ background: colors.primary, paddingBottom: "2rem" }}
        >
          <Typography variant="h6" color="inherit" className={classes.header}>
            Sledujte nás
          </Typography>
          <Box>
            <Link
              className={classes.link}
              href="https://www.facebook.com/centrumdopravnihovyzkumu"
            >
              <FacebookIcon className={classes.icon} />
            </Link>
            <Link
              className={classes.link}
              href="https://www.youtube.com/channel/UCGMzV8k2T_q9rgyCpTWVexA"
            >
              <YouTubeIcon className={classes.icon} />
            </Link>
            <Link
              className={classes.link}
              href="https://cz.linkedin.com/company/cdv---transport-research-centre"
            >
              <LinkedInIcon className={classes.icon} />
            </Link>
            <Link
              className={classes.link}
              href="https://twitter.com/dopravni_vyzkum?lang=en"
            >
              <TwitterIcon className={classes.icon} />
            </Link>
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          md={4}
          className={classes.flexCenter}
          sx={{
            background: width < 576 ? colors.lightBlue : colors.primary,
            paddingBottom: "2rem",
          }}
        >
          <Typography variant="h6" color="inherit" className={classes.header}>
            Nenechte si ujít
          </Typography>
          {/* <Box> */}
          <Link className={classes.link} href="https://www.avcrashes.net/">
            <Typography variant="body1" sx={{ color: colors.white }}>
              Nehody autonomních vozidel
            </Typography>
          </Link>

          <Link className={classes.link} href="https://czrso.cz/">
            <Typography variant="body1" sx={{ color: colors.white }}>
              Bezpečnost silničního provozu
            </Typography>
          </Link>

          {/* <Link className={classes.link} href="https://nehody.cdv.cz/">
            <Typography variant="body1">Nehody v čr</Typography>
          </Link> */}
          <Link className={classes.link} href="https://www.autonomne.cz/">
            <Typography variant="body1" sx={{ color: colors.white }}>
              Autonomní doprava
            </Typography>
          </Link>
          <Link className={classes.link} href="https://www.cistadoprava.cz/">
            <Typography variant="body1" sx={{ color: colors.white }}>
              Čistá doprava
            </Typography>
          </Link>
        </Grid>
        <Grid
          item
          xs={12}
          md={4}
          className={classes.flexCenter}
          sx={{
            background:
              width > 576 && width < 768 ? colors.lightBlue : colors.primary,
            paddingBottom: "2rem",
          }}
        >
          <Typography variant="h6" color="inherit" className={classes.header}>
            Zpracovalo
          </Typography>
          <Link
            href="https://www.cdv.cz"
            target="_blank"
            className={classes.logoLink}
          >
            <img
              src="/images/cdv-cs.png"
              alt="logo-cdv"
              className={classes.logo}
            />
          </Link>
        </Grid>
      </Grid>

      <Grid container>
        <Grid
          item
          xs={12}
          sx={{ background: colors.lightBlue, padding: "1.3rem" }}
        >
          <Link
            className={classes.link}
            href="https://www.cdv.cz"
            target="_blank"
          >
            <Typography
              sx={{ color: colors.white }}
              align="center"
              variant="body1"
            >
              &copy;{" "}
              {`${new Date().getFullYear()} Centrum dopravního výzkumu, v. v. i.`}
            </Typography>
          </Link>
        </Grid>
      </Grid>
    </footer>
  );
};

export default Footer;
