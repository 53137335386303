import { FC } from "react";
import { Button, Grid, Typography, Paper } from "@mui/material";
import measureTypes from "../data/measureTypes";
import { makeStyles } from "@mui/styles";
import { NavLink } from "react-router-dom";
import { paths } from "../config/routes";

const useStyles = makeStyles({
  gridContainer: {
    padding: "1rem",
  },
  gridItem: {
    display: "flex",
    flexDirection: "column",
    padding: "0 1rem 0 1rem",
  },
  button: { marginBottom: "1rem" },
  navLink: {
    width: "100%",
    textDecoration: "none",
  },
});
interface ILinkButtonProps {
  fullWidth: boolean;
  text: string;
  color: any;
  size: any;
  variant: any;
  to: string;
  style: any;
}

const LinkButton: FC<ILinkButtonProps> = ({
  fullWidth,
  text,
  color,
  size,
  variant,
  to,
  style,
}) => {
  const classes = useStyles();
  // console.log("variant", props.variant);

  return (
    <NavLink to={to} className={classes.navLink}>
      <Button
        variant={variant}
        color={color}
        size={size}
        fullWidth={fullWidth}
        style={style}
      >
        {text}
      </Button>
    </NavLink>
  );
};

export default LinkButton;
