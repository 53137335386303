import { FC, useEffect } from "react";
import { Box, Typography, List, ListItem, ListItemText, Container, Link } from "@mui/material";
import useCurrentWidth from "../../hooks/useCurrentWidth";
import Footer from "../../components/Footer";
import Breadcrumb from "../../components/Breadcrumb";
import IPageProps from "../../interface/pageProps";
import { makeStyles } from "@mui/styles";
import measureTypes from "../../data/measureTypes";
import CircleIcon from "@mui/icons-material/Circle";
import colors from "../../styles/colors";



const useStyles = makeStyles({
  h6: {
    margin: "1rem 0"
  },
  list: {
    width: "100% !important",
    "& ul": { padding: 0 },
    margin: "1rem 0"
  },
  para: {
    margin: "1rem 0"
  }
});

const AboutPage: FC<IPageProps> = ({ toggleLoading, loading }) => {
  const classes = useStyles();
  const width = useCurrentWidth();
  useEffect(() => {
    window.scrollTo(0, 0);
    let timer = setTimeout(() => {
      loading && toggleLoading();
    }, 200);
    return () => {
      clearTimeout(timer);
    };
  }, []);

  return (
    <>
      <Container maxWidth='xl'>
        <Box sx={width > 768 ? { p: 3 } : { p: 2 }}>
          <Breadcrumb childs={[{ id: 1, name: "O metodice" }]} />
          <Typography variant="h3" align="center">
            O Metodice
          </Typography>
          <Typography variant="h5" align="center">
            Postup v případě zjištění zvýšeného výskytu trespassingu nebo možnosti
            vzniku nového zdroje trespassingu
          </Typography>
          <Typography variant="h6" className={classes.h6}>Řešení existujícího problému</Typography>
          <Typography variant="body1" className={classes.para}>
            Pro řešení případů trespassingu je v zahraničí úspěšně využíván
            standardizovaný postup CARE (Community – Analysis – Response -
            Evaluation). Jde o aplikaci obecného modelu pro řešení problémů, který
            je dobře použitelný i v našich podmínkách. Dodržení standardizovaného
            postupu pomůže při volbě a realizaci opatření tak, aby byla zajištěna
            maximální účinnost a nevzniklo další z mnoha neúčinných nebo dokonce
            kontraproduktivních „opatření pro opatření“.
          </Typography>
          <Typography variant="h6" className={classes.h6}>
            1. Identifikace problému a kontaktování relevantních organizací
          </Typography>
          <Typography variant="body1" className={classes.para}>
            Iniciátor akce nejdříve popíše, o jaký problém se jedná (např.
            „studenti si zkracují cestu do školy přes koleje v ulici Jungmannova“;
            „obyvatelé sídliště nevyužívají existujícího podchodu a přecházejí po
            nelegální zkratce u obchodního centra o 500 m dále“ atd.) a pokusí se
            najít osoby nebo organizace, které by v tomto případě mohly být při
            řešení nápomocny. Problematika neoprávněných vstupů do prostoru dráhy
            je totiž velmi komplexní a na řešení musí zpravidla spolupracovat více
            subjektů. Kromě správce trati a správy příslušné obce se mohou podle
            místa a charakteru problému zapojit i další organizace (např. PČR,
            strážníci, škola, občanské sdružení, majitelé přilehlých pozemků…).
          </Typography>
          <Typography variant="h6" className={classes.h6}>2. Analýza problému</Typography>
          <Typography variant="body1" className={classes.para}>
            Následuje systematické shromáždění veškerých informací. Měly by být
            zjištěny údaje o případných nehodách a skoronehodách, proveden
            pozorovací a dotazovací průzkum uživatelů na místě, kde se trespassing
            vyskytuje (je zjišťována frekvence, čas, směr, motivace), dotazovací
            průzkum v blízkém okolí (obyvatelé, podniky, školy). Z průzkumu by
            měly vyplynout odpovědi na otázky kdo, co, kdy, kde, proč, jak, tedy:
          </Typography>
          <List

            className={classes.list}
          >
            {listProblemAnalysis.map(cur => 
            <ListItem key={cur.id} disablePadding>
                <CircleIcon
                  sx={{
                    color: colors.black,
                    fontSize: "0.5rem",
                    marginRight: "1rem",
                  }}
                />
                <ListItemText
                  primary={cur.text}
                />
              </ListItem>
            )}
            
          </List>
          <Typography variant="body1" className={classes.para}>
            Zodpovězení těchto otázek také napomůže v určení, o jaký typ rizikové
            lokality se jedná:
          </Typography>
          <List
            className={classes.list}
          >
            {measureTypes[0].data.map(cur => 
              <ListItem key={cur.id} disablePadding>
                  <CircleIcon
                    sx={{
                      color: colors.black,
                      fontSize: "0.5rem",
                      marginRight: "1rem",
                    }}
                  />
                  <ListItemText
                    primary={<Link href={`/opatreni/vypis?typ=${measureTypes[0].id}&subtyp=${cur.id}`}>{cur.name}</Link>}
                  />
                </ListItem>
              )}
          </List>
          <Typography variant="body1" className={classes.para}>
            Správné zařazení napomůže při stanovení postupu řešení a volbě účinných opatření. 
          </Typography>
          <Typography variant="h6" className={classes.h6}>3. Realizace opatření</Typography>
          <Typography variant="body1" className={classes.para}>
            Jakmile je zřejmý charakter výchozího problému a jeho rozsah, je třeba
            sestavit optimální řešení s ohledem na dostupné prostředky a vytvořit
            plán realizace. Důležitou součástí je jasné vymezení odpovědnosti za
            uskutečnění jednotlivých kroků. Pak je možné přistoupit k realizaci
            plánu a aplikovat opatření:
          </Typography>
          <List
            className={classes.list}
          >
            {measureTypes[1].data.map(cur => 
              <ListItem key={cur.id} disablePadding>
                  <CircleIcon
                    sx={{
                      color: colors.black,
                      fontSize: "0.5rem",
                      marginRight: "1rem",
                    }}
                  />
                  <ListItemText
                    primary={<Link href={`/opatreni/vypis?typ=${measureTypes[1].id}&subtyp=${cur.id}`}>{cur.name}</Link>}
                  />
                </ListItem>
              )}
          </List>  
          <Typography variant="body1" className={classes.para}>
            Zpravidla je žádoucí použít kombinaci několika opatření. Například
            vybudujeme-li bezpečný podchod, který vyhovuje zjištěným požadavkům
            uživatelů na volbu trasy a pohodlí, budou lidé pravděpodobně i poté ze
            zvyku využívat dosavadní nelegální trasu. Proto je vhodné po
            přechodnou dobu jednak jasně informovat o nové možnosti přecházení
            (informační tabulky, oznámení v místním zpravodaji), jednak jim v
            užívání nebezpečné zkratky zabránit technologickým opatřením (plot,
            zábradlí, neschůdné rohože…) nebo např. přechodným nasazením
            policejního dozoru.
          </Typography>
          <Typography variant="h6" className={classes.h6}>4. Evaluace</Typography>
          <Typography variant="body1" className={classes.para}>
            Posledním a velmi důležitým krokem procesu je evaluace, vyhodnocení
            výsledků aplikace opatření. Provedeme-li znovu pozorovací průzkum,
            ideálně krátce po realizaci opatření i s několikaměsíčním odstupem,
            zjistíme, zda je problém
          </Typography>
          <List

            className={classes.list}
          >
            {listEvaluation.map(cur => 
            <ListItem key={cur.id} disablePadding>
                <CircleIcon
                  sx={{
                    color: colors.black,
                    fontSize: "0.5rem",
                    marginRight: "1rem",
                  }}
                />
                <ListItemText
                  primary={cur.text}
                />
              </ListItem>
            )}
            
          </List>
          <Typography variant="h6" className={classes.h6} fontStyle="italic">Je možná i prevence? </Typography>
          <Typography variant="body1" className={classes.para}>
            Nachází-li se v obci nebo její blízkosti železniční trať, je třeba mít
            vždy na paměti, že i když problém neoprávněných vstupů aktuálně
            neexistuje, může za určitých okolností nastat. Typicky tato situace
            vzniká při změnách ve využití území – výstavba nových obytných
            souborů, obchodních center, škol, průmyslových podniků nebo i
            volnočasových areálů. Každá taková aktivita může vyvolat vznik nových
            pěších vazeb přes trať. Jestliže se poptávka po možnosti přecházení
            včas neřeší bezpečným způsobem, vytvoří si lidé sami nelegální
            cestičky. Proto je dobré nové projekty vždy předem posuzovat i z
            tohoto hlediska.
          </Typography>
          <Typography variant="body1" className={classes.para}>
            Postup je podobný jako u již existujících problémů, ve čtyřech
            krocích: identifikace potenciálního problému, jeho analýza (kdo, co,
            kdy, kde, proč, jak), provedení úprav projektu tak, aby při realizaci
            nenastaly podmínky vedoucí ke vzniku trespassingu, vyhodnocení (zde je
            třeba určit, zda bude nutné sledování dlouhodobější, případně kdo a
            jakým způsobem ho má zajišťovat).
          </Typography>
          <Typography variant="h6" className={classes.h6} fontStyle="italic">Zdroje</Typography>
          <Typography variant="body1" className={classes.para}>
            Operation Lifesaver {" "}
            <Link href="https://oli.org/" target="_blank">https://oli.org/</Link>
          </Typography>
          <Typography variant="body1" className={classes.para}>
            Community Trespassing Prevention Guide, December 2011, U.S. Department of Transportation Federal Railroad Administration Office of Railroad Safety 
          </Typography>


        </Box>
      </Container>
      <Footer />
    </>
  );
};

export default AboutPage;

export const listProblemAnalysis = [
  {id: 1, text: "Kdo neoprávněně vstupuje na koleje (věk, pohlaví, sociální skupina...)?"},
  {id: 2, text: "Co tam přesně dělají (chůze po kolejích, podél kolejí, přes koleje, pěšky, s bicyklem, zkracování cesty, hra, schůzky a popíjení, trestná činnost…)?"},
  {id: 3, text: "Kdy se trespassing odehrává (denní doba, případně roční období, dny v týdnu…)?"},
  {id: 4, text: "Kudy uživatelé vstupují na koleje, odkud a kam směřují?"},
  {id: 5, text: "Proč se trespassingu dopouštějí, jak daleko je nejbližší legální alternativa a jak vypadá?"},
  {id: 6, text: "Jak se na koleje dostanou (snadný přístup, překonávání terénní překážky, plotu…)?"}
];

export const listEvaluation = [
  {id: 1, text: "zcela odstraněn "},
  {id: 2, text: "zredukován "},
  {id: 3, text: "přesunut na jiné místo"},
  {id: 4, text: "zcela nezměněn"}
]




