// Imports

import CircleIcon from "@mui/icons-material/Circle";

import IElement from "../interface/element";
import colors from "../styles/colors";
import { Box, ListItemText, ListItem, List, Typography } from "@mui/material";
import LinkButton from "../components/LinkButton";
import ImageGallery from "react-image-gallery";

export const renderElement = (element: IElement) => {
  const type = element.type;
  const content = element.content;
  const key = element.id;
  const name = element.name;
  if (type === "paragraph") {
    return (
      <Typography key={key} variant="body1" sx={{ my: 3 }}>
        {content}
      </Typography>
    );
  } else if (type === "header") {
    return (
      <Typography key={key} variant="h4" align="center">
        {content}
        <LinkButton
          to={`/opatreni/vypis?typ=1&subtyp=1`}
          fullWidth={false}
          text={"Zpět na výpis"}
          color={"success"}
          size={"large"}
          variant={"contained"}
          style={{ display: "block", margin: "1.5rem auto 0 auto" }}
        />
      </Typography>
    );
  } else if (type === "subheader") {
    return (
      <Typography key={key} variant="h5" align="left">
        {content}
      </Typography>
    );
  } else if (type === "list") {
    return (
      <List key={key}>
        {content.map((cur: any) => (
          <ListItem
            disablePadding
            key={cur.id}
           
          >
              <CircleIcon
                sx={{
                  color: colors.black,
                  fontSize: "0.5rem",
                  marginRight: "1rem",
                }}
              />

            <ListItemText primary={cur.content} />
          </ListItem>
        ))}
      </List>
    );
  } else if (type === "pictures") {
    const pictures = content.map((cur: any) => {
      const curSrc = `/images/measures/${cur.src}`;
      return {
        original: curSrc,
        thumbnail: curSrc,
        // description: cur.name
      };
    });
    // console.log("content", content);

    return (
      <Box key={key} sx={{ margin: "2rem 0" }}>
          <Typography
            variant="body1"
            fontStyle={"italic"}
            fontWeight={"bold"}
            textAlign={"left"}
            sx={{ mb:2 }}
            
          >
            {name}
          </Typography>

          <ImageGallery
            items={pictures}
            useTranslate3D={false}
            showPlayButton={false}
            showFullscreenButton={false}
            showThumbnails={false}
            lazyLoad={true}
            showBullets={content.length > 1 ? true : false}
            infinite={true}
            // thumbnailAlt="kkk"
          />

      </Box>
    );
  }
};
