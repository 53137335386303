import { FC, useEffect } from "react";
import { Box, Grid, Typography, Paper, Link } from "@mui/material";
import measureTypes from "../../data/measureTypes";
import { makeStyles } from "@mui/styles";
import { NavLink } from "react-router-dom";
import { paths } from "../../config/routes";
import Breadcrumb from "../../components/Breadcrumb";
import colors from "../../styles/colors";
import LinkButton from "../../components/LinkButton";
import useCurrentWidth from "../../hooks/useCurrentWidth";
import IPageProps from "../../interface/pageProps";

const useStyles = makeStyles({
  paper: {
    background: colors.white,
    position: "relative",
    zIndex: 10,
    width: "90%",
    margin: "2rem auto 2rem auto",
    marginBottom: "2rem",
    padding: "1rem",
    "@media (min-width: 768px)": {
      width: "80%",
      padding: "1.5rem",
    },
    "@media (min-width: 1200px)": {
      width: "60%",
      padding: "2rem",
    },
  },
  bgImage: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100vh",
    backgroundImage: 'url("/images/homepage.jpg")',
    filter: "grayscale(100%)",
  },
  header: {
    fontWeight: "bold",
    marginBottom: "2rem",
    marginTop: "0 !important"
  },
  gridContainer: {
    marginTop: "1rem",
  },
  logo: {
    height: "65px",
  },
  link: {
    display: "flex",
    justifyContent: "center",
    marginBottom: "2rem",
  },
});

const HomePage: FC<IPageProps> = ({ toggleLoading, loading }) => {
  const classes = useStyles();
  const width = useCurrentWidth();

  useEffect(() => {
    window.scrollTo(0, 0);
    let timer = setTimeout(() => {
      loading && toggleLoading();
    }, 200);
    return () => {
      clearTimeout(timer);
    };
  }, []);

  return (
    <>
      <Box className={classes.bgImage}></Box>
      <Paper elevation={4} className={classes.paper}>
        <Typography
          variant={width > 1200 ? "h3" : "h4"}
          align="center"
          className={classes.header}
        >
          Představujeme Vám metodiku o nepovolených vstupech do kolejišť.
        </Typography>
        <Link
          href="https://www.cdv.cz"
          target="_blank"
          className={classes.link}
        >
          <img
            src="/images/cdv-cs.png"
            alt="logo-cdv"
            className={classes.logo}
          />
        </Link>

        <Typography variant="body1" sx={{ pb: 2 }} textAlign="justify">
          Metodika užití preventivních opatření proti vzniku nehod v důsledku neoprávněných vstupů do
          prostoru dráhy shrnuje problematiku tzv. “trespassingu“ v České republice, uvádí podrobný postup v
          případě zjištění zvýšeného výskytu neoprávněných vstupů a pro jednotlivé typy rizikových lokalit
          nabízí možnosti vhodných preventivních opatření.
        </Typography>
        <Typography variant="body1" textAlign="justify">
          Tato metodika byla zpracována v rámci projektu Výzkum podmínek a návrh opatření pro omezení
          počtu a zmírnění následků neoprávněných vstupů chodců do prostoru dráhy (TA04030327), za
          podpory Technologické agentury České republiky v rámci programu ALFA a na výzkumné
          infrastruktuře pořízené z Operačního programu Výzkum a vývoj pro inovace
          (CZ.1.05/2.1.00/03.0064).
        </Typography>
        <Typography
          variant="h4"
          align="center"
          className={classes.header}
          style={{ marginTop: "1rem", marginBottom: "1rem" }}
        >
          Kam dál?
        </Typography>
        <Grid container className={classes.gridContainer}>
          <Grid item xs={12}>
            <LinkButton
              to={`/opatreni`}
              fullWidth={true}
              text={"Přejít na opatření"}
              color={"success"}
              size={"large"}
              variant={"contained"}
              style={{ marginTop: "1rem" }}
            />
          </Grid>
          <Grid item xs={12}>
            <LinkButton
              to={`/o-metodice`}
              fullWidth={true}
              text={"O metodice"}
              color={"success"}
              size={"large"}
              variant={"contained"}
              style={{ marginTop: "1rem" }}
            />
          </Grid>
        </Grid>
      </Paper>
    </>
  );
};

export default HomePage;
