import { FC } from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  Box,
  IconButton,
  Container,
} from "@mui/material";

import { makeStyles } from "@mui/styles";
import { NavLink } from "react-router-dom";

import colors from "../styles/colors";
import { paths } from "../config/routes";

import MenuIcon from "@mui/icons-material/Menu";
import HomeIcon from "@mui/icons-material/Home";
import InfoIcon from "@mui/icons-material/Info";
import TrainIcon from "@mui/icons-material/Train";
import useCurrentWidth from "../hooks/useCurrentWidth";
const useStyles = makeStyles({
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
    paddingTop: "0.6rem",
    paddingBottom: "0.6rem",
  },
  header: {
    textTransform: "uppercase",
    textAlign: "center",

    margin: "0",
  },
  bigScreenHide: {
    display: "block",
    "@media (min-width: 768px)": {
      display: "none",
    },
  },
  smallScreenHide: {
    display: "none",
    "@media (min-width: 768px)": {
      display: "block",
    },
  },
  link: {
    marginLeft: "1rem",
    textDecoration: "none",
    color: colors.white,
    "&:hover": {
      color: colors.white,
    },
  },
  activeLink: {
    marginLeft: "1rem",
    textDecoration: "none",
    color: colors.secondary,
  },
  navText: {},
});

interface INavbarProps {
  toggleSidebar: () => void;
}

const Navbar: FC<INavbarProps> = ({ toggleSidebar }) => {
  const classes = useStyles();
  const width = useCurrentWidth();

  return (
    <AppBar position="sticky">
      <Container maxWidth={'xl'}>
        <Toolbar
          variant="dense"
          className={classes.toolbar}
          sx={width > 768 ? { pl: 3, pr: 3 } : { pl: 2, pr: 2 }}
        >
          <Box>
            <Typography variant="h5" color="inherit" className={classes.header}>
              Trespassing
            </Typography>
          </Box>
          <Box className={classes.smallScreenHide}>
            <NavLink
              to={paths.homePage}
              className={(navData) =>
                navData.isActive ? classes.activeLink : classes.link
              }
            >
              <Button color="inherit" startIcon={<HomeIcon />}>
                Domů
              </Button>
            </NavLink>
            <NavLink
              to={paths.measuresPage}
              className={(navData) =>
                navData.isActive ? classes.activeLink : classes.link
              }
            >
              <Button color="inherit" startIcon={<TrainIcon />}>
                Opatření
              </Button>
            </NavLink>

            <NavLink
              to={paths.aboutPage}
              className={(navData) =>
                navData.isActive ? classes.activeLink : classes.link
              }
            >
              <Button color="inherit" startIcon={<InfoIcon />}>
                O metodice
              </Button>
            </NavLink>
          </Box>
          <IconButton
            className={classes.bigScreenHide}
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={toggleSidebar}
          >
            <MenuIcon
              sx={{ fontSize: "2rem", position: "relative", top: "0.2rem" }}
            />
          </IconButton>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default Navbar;
