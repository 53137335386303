import { FC, useEffect, useState } from "react";
import { Box, Container } from "@mui/material";
import measures from "../../data/measures";
// import config from "../../config/config";
import Detail from "../../components/Detail";
import { useParams } from "react-router-dom";
import IElement from "../../interface/element";
import Breadcrumb from "../../components/Breadcrumb";
import useCurrentWidth from "../../hooks/useCurrentWidth";
import IPageProps from "../../interface/pageProps";

const MeasureDetailPage: FC<IPageProps> = ({ toggleLoading, loading }) => {
  let { id } = useParams();
  const width = useCurrentWidth();

  const [elements, setElements] = useState<IElement[]>([]);

  useEffect(() => {
    const curMeasure = measures.find((cur) => cur.id === Number(id));

    curMeasure && setElements((prev) => (prev = curMeasure.elements));
    // console.log(curMeasure);
  }, [id]);

  useEffect(() => {
    window.scrollTo(0, 0);
    let timer = setTimeout(() => {
      loading && toggleLoading();
    }, 200);
    return () => {
      clearTimeout(timer);
    };
  }, []);

  return (
    <Container maxWidth="xl">
      <Box sx={width > 768 ? { p: 3 } : { p: 2 }}>
        <Breadcrumb
          childs={[
            { id: 1, name: "Opatření", to: "/opatreni" },
            { id: 2, name: "Výpis", to: "/opatreni/vypis?typ=1&subtyp=1" },
            { id: 3, name: "Detail" },
          ]}
        />
        {elements && elements.length > 0 && <Detail elements={elements} />}
      </Box>
    </Container>
  );
};

export default MeasureDetailPage;
