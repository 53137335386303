import { FC, useEffect } from "react";
import { Grid, Typography, Paper, Box, Container } from "@mui/material";
import measureTypes from "../../data/measureTypes";
import { makeStyles } from "@mui/styles";
import { paths } from "../../config/routes";
import LinkButton from "../../components/LinkButton";
import Breadcrumb from "../../components/Breadcrumb";
import useCurrentWidth from "../../hooks/useCurrentWidth";
import Footer from "../../components/Footer";
import IPageProps from "../../interface/pageProps";

const useStyles = makeStyles({
  gridContainer: {
    padding: "1rem",
  },
  gridItem: {
    display: "flex",
    flexDirection: "column",
    padding: "0 1rem 0 1rem",
  },
  navLink: {
    width: "100%",
    textDecoration: "none",
  },
});

const MeasuresPage: FC<IPageProps> = ({ toggleLoading, loading }) => {
  const classes = useStyles();
  const width = useCurrentWidth();

  useEffect(() => {
    // !loading && toggleLoading();

    window.scrollTo(0, 0);
    let timer = setTimeout(() => {
      loading && toggleLoading();
    }, 200);
    return () => {
      clearTimeout(timer);
    };
  }, []);

  const filterGridItem = measureTypes.map((measureType) => {
    // Filter type
    const curMeasureType = measureType.id;
    const links = measureType.data.map((cur) => {
      const measureSubTypeId = cur.id;
      return (
        <LinkButton
          to={`${paths.measuresPreviewPage}?typ=${curMeasureType}&subtyp=${measureSubTypeId}`}
          fullWidth={true}
          key={measureSubTypeId}
          text={cur.name}
          color={"success"}
          size={"large"}
          variant={"contained"}
          style={{ marginBottom: "1rem" }}
        />
      );
    });

    const gridItem = (
      <Grid
        key={measureType.id}
        item
        xs={12}
        md={6}
        className={classes.gridItem}
      >
        <Typography align="center" variant="h5">
          {measureType.name}
        </Typography>
        {links}
      </Grid>
    );

    return gridItem;
  });

  return (
    <>
      <Container maxWidth="xl">
        <Box sx={width > 768 ? { p: 3 } : { p: 2 }}>
          <Breadcrumb childs={[{ id: 1, name: "Opatření" }]} />
          <Typography variant="h3" align="center">
            Opatření
          </Typography>
          <Paper elevation={3}>
            <Grid container className={classes.gridContainer}>
              <Grid item xs={12} className={classes.gridItem}>
                <Typography variant="h4" align="center" sx={{ marginBottom: "0.5rem" }}>
                  Filtrace podle typu
                </Typography>
              </Grid>
              {filterGridItem}
              <Grid item xs={12} className={classes.gridItem}>
                <Typography variant="h4" align="center" sx={{ mt: 2 }}>
                  Všechna opatření
                </Typography>
              </Grid>

              <Grid item xs={12} className={classes.gridItem}>
                <LinkButton
                  to={`/opatreni/vypis`}
                  fullWidth={true}
                  text={"Zobrazit všechna opatření"}
                  color={"primary"}
                  size={"large"}
                  variant={"contained"}
                  style={{}}
                />
              </Grid>
            </Grid>
          </Paper>
        </Box>
      </Container>
      <Footer />
    </>
  );
};

export default MeasuresPage;
