import { FC } from "react";
import { Paper, Typography, Button } from "@mui/material";

import { makeStyles } from "@mui/styles";
import { NavLink } from "react-router-dom";

import { paths } from "../config/routes";

const useStyles = makeStyles({
  para: {
    "overflow":"hidden",
    "textOverflow":"ellipsis",
    "display":"-webkit-box",
    "WebkitLineClamp":"3",
    "WebkitBoxOrient":"vertical",
    textAlign: "justify",
  },
  navLink: {
    textDecoration: "none",
  },
  button: {
    marginTop: "1rem",
    width: "100%",
  },
  paper: {
    padding: "1rem",
    marginBottom: "1rem",
    "&:last-child": {
      marginBottom: "0",
    },
  },
});

interface IPaperPreviewProps {
  heading: string;
  text: string;
  gotoText: string;
  id: number;
}

const PaperPreview: FC<IPaperPreviewProps> = ({
  heading,
  text,
  gotoText,
  id,
}) => {
  const classes = useStyles();
  // console.log("Re render paper view");

  return (
    <Paper elevation={3} className={classes.paper} key={id}>
      <Typography variant="h5">
        {heading}
      </Typography>
      <Typography variant="body1" className={classes.para}>
        {text}
      </Typography>
      <NavLink
        to={`${paths.measureDetailPage}/${id}`}
        className={classes.navLink}
      >
        <Button variant="contained" color="info" className={classes.button}>
          {gotoText}
        </Button>
      </NavLink>
    </Paper>
  );
};

// export default memo(PaperPreview);
export default PaperPreview;
